<!-- 账号管理 -->
<style scoped>
.width150 {
  width: 150px;
}
</style>
<template>
  <div>
    <div style="background-color: #fff; padding: 16px; margin-bottom: 20px">
      <div>
        <!-- <a-button type="primary" @click="doReady()">开单</a-button> -->
        <!-- 搜索条件 -->
        <a-form-model
          size="small"
          layout="inline"
          :model="ajaxparams"
          @keyup.native.enter="$refs.myTable.search()"
        >
          <a-form-model-item label="业务员">
            <a-input
              placeholder="请输入业务员名称"
              v-model="ajaxparams['salesman_no']"
            />
          </a-form-model-item>
          <a-form-model-item label="订单号">
            <a-input
              placeholder="请输入订单号"
              v-model="ajaxparams['1_order_sn_like']"
            />
          </a-form-model-item>
          <a-form-model-item label="用户昵称">
            <a-input
              placeholder="请输入用户昵称"
              v-model="ajaxparams['3_name_like']"
            />
          </a-form-model-item>
          <a-form-model-item label="商品名称">
            <a-input
              placeholder="请输入商品名称"
              v-model="ajaxparams['2_goods_name_like']"
            />
          </a-form-model-item>
          <a-form-model-item label="是否预售" prop="is_presale">
            <a-select
              allowClear
              class="width150"
              v-model="ajaxparams['is_presale']"
              placeholder="请选择"
            >
              <a-select-option :value="1">是</a-select-option>
              <a-select-option :value="0">否</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-button
              icon="search"
              @click="$refs.myTable.search()"
              type="primary"
              >搜索</a-button
            >
          </a-form-model-item>
          <a-form-model-item>
            <a-button @click="doExport" type="primary"> 导出 </a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
      <MyTable
        class="mt10"
        :apiUrl="orders_index"
        :params="ajaxparams"
        ref="myTable"
        :columns="columns"
        @selectRow="onSelectChange"
        :isSelection="false"
      >
        <a-space slot="button" slot-scope="item, row, index">
          <a-popconfirm
            title="确定是否开单?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="doReady(row.id, index)"
          >
            <a-button size="small" type="primary">开单</a-button>
          </a-popconfirm>

        </a-space>
        <a-space slot="img" slot-scope="item, row, index">
          <img
            v-if="row.img"
            style="width: 30px; heigth: 30px"
            :src="IMGHOST + '/' + row.img"
          />
          <span v-else>-</span>
        </a-space>
        <a-space slot="activity_id" slot-scope="item, row, index">
          <span style="color:red;">{{ item == 16 ? "赠品" : "" }}</span>
        </a-space>
        <a-space slot="is_presale" slot-scope="item, row, index">
          <span>{{ row.is_presale == 0 ? "否" : "是" }}</span>
        </a-space>
        <a-space slot="order_sn" slot-scope="item, row, index">
          <router-link
            target="_blank"
            :to="{ path: '/orders/detail', query: { id: row.order_sn } }"
          >
            <span>{{ row.order_sn }}</span>
          </router-link>
        </a-space>
        <a-space slot="manage_id" slot-scope="item, row, index">
          <span>{{ salesmen[item] ? salesmen[item]["name"] : "-" }}</span>
        </a-space>
      </MyTable>
    </div>
  </div>
</template>
<script>
import MyTable from "@/components/myTable.vue";
import { orders_unready_index, orders_doReady } from "@/api/orders";
import { users_salesman } from "@/api/users";
import { order_unready } from "@/api/export";
export default {
  components: { MyTable },
  data() {
    return {
      orders_index: orders_unready_index,
      IMGHOST: window.IMGHOST,

      ajaxparams: {}, //请求参数
      /*
			列表header
		*/
      columns: [
        {
          title: "操作",
          key: "operation",
          width: 80,
          ellipsis: true,
          fixed: "left",
          scopedSlots: { customRender: "button" },
        },
        {
          title: "订单备注",
          key: "activity_id",
          dataIndex: "activity_id",
          width: 120,
          scopedSlots: { customRender: "activity_id" },
        },
        {
          title: "订单号",
          key: "order_sn",
          dataIndex: "order_sn",
          width: 130,
          scopedSlots: { customRender: "order_sn" },
        },
        {
          title: "商品名称",
          ellipsis: true,
          width: 220,
          dataIndex: "goods_name",
          key: "goods_name",
        },
        {
          title: "用户名称",
          width: 100,
          dataIndex: "name",
          key: "name",
        },
        {
          title: "业务员",
          dataIndex: "manage_id",
          key: "manage_id",
          width: 100,
          scopedSlots: { customRender: "manage_id" },
        },
        {
          title: "是否预售",
          ellipsis: true,
          dataIndex: "is_presale",
          key: "is_presale",
          width: 100,
          scopedSlots: { customRender: "is_presale" },
        },
        {
          title: "单价",
          ellipsis: true,
          width: 100,
          dataIndex: "price",
          key: "price",
        },
        {
          title: "购买数量",
          ellipsis: true,
          width: 120,
          dataIndex: "num",
          key: "num",
        },
        {
          title: "规格",
          ellipsis: true,
          width: 300,
          dataIndex: "goods_spec_desc",
          key: "goods_spec_desc",
        },
        // {
        //   title: "规格图片",
        //   dataIndex: "img",
        //   key: "img",
        //   width: 100,
        //   scopedSlots: { customRender: "img" },
        // },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "ctime",
          key: "ctime",
          width: 220,
        },
      ],
      selectGoods: [],
      salesmen: {},
    };
  },
  mounted() {
    this.salesmen = this.$db.get("salesmen");
    if (!this.salesmen) {
      users_salesman().then((res) => {
        this.salesmen = res;
        console.log("users_salesman", this.salesmen);
      });
    }
  },
  methods: {
    doExport() {
      //导出
      order_unready(this.ajaxparams).then(
        (res) => {
          this.$message.success("添加导出队列成功");
          this.$db.set("is_export", 1);
        },
        (err) => {
          this.$message.warning("导出失败");
        }
      );
    },
    onSelectChange(val) {
      console.log(val);
      if (val && val.length > 0) {
        this.selectGoods = val.map((item) => item.id);
      }
    },
    async doReady(id) {
      let ids;
      if (id) {
        ids = [id];
      } else {
        if (this.selectGoods.length > 0) {
          ids = this.selectGoods;
        } else {
          this.$message.warning("请选择至少一个规格");
          return;
        }
      }
      orders_doReady({ ids: ids, status: 1 })
        .then((result) => {
          this.$message.success("操作成功");
          setTimeout(() => {
            this.$refs.myTable.getList();
          }, 1500);
        })
        .catch(() => {});
    },
  },
};
</script>
