<!-- 广告管理 -->

<style scoped>
.img {
  /* width:30px; */
  height: 30px;
}
.ellipsis-1 {
  max-width: 180px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-all;
}
.w150 {
  width: 150px;
}
</style>
<template>
  <div class="pageCentent">
    <!-- 搜索条件 -->
    <div>
      <MyTable
        class="mt10"
        :isSelection="false"
        :apiUrl="apiUrl"
        :params="ajaxparams"
        ref="myTable"
        :columns="columns"
      >
        <a-space slot="cname" slot-scope="item,row,index">
          <span>{{levels[row.cname]}}</span>
        </a-space>
        <a-space slot="button" slot-scope="item,row,index">
          <a-button size="small" @click="onEdit(row)" type="primary" :disabled="row.is_editable !== 1">编辑</a-button>
        </a-space>
        
      </MyTable>
    </div>
    <a-modal :footer="null" :maskClosable="false" v-model="dialogVisible" width="80%" :title="'编辑'+levels[dialogContent.cname]" :confirm-loading="submitLoading" @close="handleCancel" @cancel="handleCancel" >
      <updateCom ref="updateCom" v-if="dialogVisible" @submit="onSubmit" :obj="dialogContent"></updateCom>
    </a-modal>
  </div>
</template>
<script>
import MyTable from "@/components/myTable.vue"
import updateCom from "./components/update.vue"
import { config_index } from "@/api/config"
export default {
  components: { MyTable, updateCom },
  data() {
    return {
      IMGHOST: window.IMGHOST,
      apiUrl: config_index,
      ajaxparams: {},
      submitLoading:false,
      dialogVisible:false,
      dialogType:'',
      dialogContent:{},
      levels:{
        'sys_desc_member_primary':'体验会员',
        'sys_desc_member_junior':'初级会员',
        'sys_desc_member_vip': 'vip',
        'sys_desc_member_A1':'A1',
        'sys_desc_member_A2':'A2',
        'sys_desc_member_A3':'A3',
        'sys_desc_member_ziyuan':"籽缘会员",
        'sign_rule':'签到规则'
      },
      columns: [
        {
          title: "类型",
          ellipsis: true,
          fixed: "left",
          dataIndex: "cname",
          key: "cname",
          scopedSlots: { customRender: "cname" },
          width:200
        },
        {
          title: "备注",
          ellipsis: true,
          fixed: "left",
          dataIndex: "remark",
          key: "remark",
          width:300
        },
        {
          title: "操作",
          ellipsis: true,
          fixed: "left",
          dataIndex: "button",
          key: "button",
          width:150,
          scopedSlots: { customRender: "button" }
        },
      ],
    }
  },
  methods:{
    onEdit(row){
      this.dialogContent = row
      this.dialogVisible = true
    },
    onSubmit(){
      this.$refs.myTable.getList()
      this.dialogVisible = false
      this.submitLoading = false
    },
    handleCancel(){
      this.dialogVisible = false
      this.submitLoading = false
    }
  }
}
</script>
	