<!-- 广告管理 -->

<style scoped>
.img {
  /* width:30px; */
  height: 30px;
}
.ellipsis-1 {
  max-width: 180px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-all;
}
.w150 {
  width: 150px;
}
</style>
<template>
  <div class="pageCentent">
    <!-- 搜索条件 -->
    <a-form-model
      size="small"
      layout="inline"
      :model="ajaxparams"
      @keyup.native.enter="$refs.myTable.search()"
    >
      <a-form-model-item label="类型">
        <a-select
          style="width: 150px"
          v-model="ajaxparams['type']"
          placeholder="请选择类型"
          allowClear="true"
        >
          <a-select-option :value="1">经销商</a-select-option>
          <a-select-option :value="2">流通商</a-select-option>
          <a-select-option :value="3">种植户</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="审核状态">
        <a-select
          style="width: 150px"
          v-model="ajaxparams['status']"
          placeholder="请选择状态"
          allowClear="true"
        >
          <a-select-option :value="-1">无效客户</a-select-option>
          <a-select-option :value="0">待联系</a-select-option>
          <a-select-option :value="1">跟进中</a-select-option>
          <a-select-option :value="2">已充值</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="备注">
        <a-input placeholder="请输入备注" v-model="ajaxparams['remark_like']" />
      </a-form-model-item>

      <a-form-model-item>
        <a-button icon="search" @click="$refs.myTable.search()" type="primary">
          搜索</a-button
        >
      </a-form-model-item>
       <a-form-model-item >
            <a-button :loading="buttonLoading"  @click="doExport" type="primary"> 导出 </a-button>
          </a-form-model-item>
    </a-form-model>
    </a-form-model>
    <div>
      <MyTable
        class="mt10"
        :isSelection="false"
        :apiUrl="apiUrl"
        :params="ajaxparams"
        ref="myTable"
        :columns="columns"
      >
        <a-space slot="button" slot-scope="item, row, index">
          <a-button
            size="small"
            @click="showChangeHandlekDialog(row)"
            type="primary"
            v-if="row.status != -1 && row.status != 2"
            >处理</a-button
          >
          <a-button size="small" @click="showChangeRemarkDialog(row)">
            修改备注
          </a-button>
        </a-space>
        <a-space slot="area_code" slot-scope="item, row, index">
          <!-- <span v-if="row.area_code">{{ row.area_code.split(',')[0] }}</span> -->
          <a-tag
            v-for="(t, i) in row.area_code ? province_code(row.area_code) : []"
            :key="i"
            >{{ t }}</a-tag
          >
        </a-space>

        <a-space slot="back_id_card_pic" slot-scope="item, row, index">
          <img
            @click="showDialog(row.back_id_card_pic)"
            v-if="row.back_id_card_pic"
            class="img"
            :src="row.back_id_card_pic"
          />
          <span v-else>-</span>
        </a-space>
        <a-space slot="license_pic" slot-scope="item, row, index">
          <img
            @click="showDialog(row.license_pic)"
            v-if="row.license_pic"
            class="img"
            :src="row.license_pic"
          />
          <span v-else>-</span>
        </a-space>
        <a-space slot="storefront_pic" slot-scope="item, row, index">
          <img
            @click="showDialog(row.storefront_pic)"
            v-if="row.storefront_pic"
            class="img"
            :src="row.storefront_pic"
          />
          <span v-else>-</span>
        </a-space>
        <a-space slot="front_id_card_pic" slot-scope="item, row, index">
          <img
            @click="showDialog(row.front_id_card_pic)"
            v-if="row.front_id_card_pic"
            class="img"
            :src="row.front_id_card_pic"
          />
          <span v-else>-</span>
        </a-space>
        <a-space slot="type" slot-scope="item, row, index">
          <span v-if="row.type == 1">经销商</span>
          <span v-else-if="row.type == 2">流通商</span>
          <span v-else-if="row.type == 3">种植户</span>
        </a-space>
        <a-space slot="status" slot-scope="item, row, index">
          <span v-if="row.status == -1">无效客户</span>
          <span v-else-if="row.status == 0"
            ><a-tag color="red"> 待联系 </a-tag></span
          >
          <span v-else-if="row.status == 1">跟进中</span>
          <span v-else-if="row.status == 2">已充值</span>
        </a-space>
        <a-space slot="id_card" slot-scope="item, row, index">
          <!-- <span>{{$common.hiddenString(row.id_card,'********',6)}}</span> -->
          <span>{{ row.id_card }}</span>
        </a-space>
        <a-space slot="bank_account" slot-scope="item, row, index">
          <span>{{
            $common.hiddenString(row.bank_account, "********", 6)
          }}</span>
        </a-space>
      </MyTable>
    </div>
    <a-modal :footer="null" v-model="dialogVisible" title="查看">
      <img style="width: 100%" :src="dialogImg" alt />
    </a-modal>

    <a-modal
      :title="'处理审核状态'"
      :visible="showHandleDialog"
      @ok="approved"
      @cancel="showHandleDialog = false"
    >
      <a-form-model-item label="审核状态">
        <a-select
          style="width: 150px"
          v-model="handleStatus"
          placeholder="请选择审核状态"
          allowClear="true"
        >
          <a-select-option :value="-1">无效客户</a-select-option>
          <a-select-option :value="0">待联系</a-select-option>
          <a-select-option :value="1">跟进中</a-select-option>
          <a-select-option :value="2">已充值</a-select-option>
        </a-select>
      </a-form-model-item>
    </a-modal>

    <a-modal
      :title="'修改备注'"
      :visible="showRemarkDialog"
      @ok="changeRemark"
      @cancel="showRemarkDialog = false"
    >
      <a-form-model-item label="备注">
        <a-textarea
          placeholder="请输入备注信息"
          v-model="remark"
          :auto-size="{ minRows: 3, maxRows: 8 }"
        >
        </a-textarea>
      </a-form-model-item>
    </a-modal>
  </div>
</template>
<script>
import MyTable from "@/components/myTable.vue";
import {
  intendCustomer_index,
  intendCustomer_deal,
  intendCustomer_changeRemark,
} from "@/api/manager.js";
import { user_apply,intend_customer } from "@/api/export";
import moment from "moment";

export default {
  components: { MyTable },
  data() {
    return {
      IMGHOST: window.IMGHOST,
      apiUrl: intendCustomer_index,
      ajaxparams: {},
      dialogImg: "",
      dialogVisible: false,
      columns: [
        {
          title: "用户名",
          ellipsis: true,
          width: 120,
          fixed: "left",
          dataIndex: "customer_name",
          key: "customer_name",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "手机号码",
          ellipsis: true,
          width: 150,
          dataIndex: "mobile",
          key: "mobile",
          scopedSlots: { customRender: "mobile" },
        },

        {
          title: "省份",
          ellipsis: true,
          dataIndex: "province",
          key: "province",
          width: 80,
        },
        // {
        //   title: "省份",
        //   ellipsis: true,
        //   dataIndex: "area_code",
        //   key: "area_code",
        //   width: 80,
        //   scopedSlots: { customRender: "area_code" },
        // },
        {
          title: "类型",
          ellipsis: true,
          dataIndex: "type",
          key: "type",
          width: 100,
          scopedSlots: { customRender: "type" },
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "status",
          key: "status",
          width: 100,
          scopedSlots: { customRender: "status" },
        },
        {
          title: "备注",
          width: 200,
          dataIndex: "remark",
          key: "remark",
          scopedSlots: { customRender: "remark" },
        },
        {
          title: "来源",
          ellipsis: true,
          dataIndex: "source",
          key: "source",
          width: 80,
        },
        {
          title: "登陆次数",
          dataIndex: "login_times",
          key: "login_times",
          width: 100,
        },
        {
          title: "最后更新时间",
          ellipsis: true,
          dataIndex: "update_at",
          key: "update_at",
          width: 200,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "create_at",
          key: "create_at",
          width: 200,
        },
        {
          title: "操作",
          key: "operation",
          fixed: "right",
          width: 200,
          scopedSlots: { customRender: "button" },
        },
      ],
      regionTree: [],
      showRemarkDialog: false,
      showHandleDialog: false,
      handleStatus: -1,
      currentId: 0,
      remark: "",
      buttonLoading: false,
    };
  },
  created() {},
  mounted() {
    let data = this.$db.get("regionList").data;
    this.regionTree = this.$common.treeData(data, "pcode", "code", 0);
  },
  destroyed() {},
  methods: {

      doExport(){
        this.buttonLoading = true
        intend_customer(this.ajaxparams).then(res => {
          this.buttonLoading = false
          this.$message.success("添加导出队列成功")
          this.$db.set('is_export',1)
        },err => {
          this.buttonLoading = false
          this.$message.warning("导出失败")
        })
        
      },
    moment,
    showDialog(src) {
      this.dialogImg = src;
      this.dialogVisible = true;
    },

    //修改备注
    showChangeRemarkDialog(row) {
      this.showRemarkDialog = true;
      this.currentId = row.id;
    },
    changeRemark() {
      if (!this.currentId || !this.remark) {
        this.$message.error("请输入备注信息");
        return false;
      }
      intendCustomer_changeRemark({
        id: this.currentId,
        remark: this.remark,
      }).then((res) => {
        this.$message.success("操作成功");
        setTimeout(() => {
          this.$refs.myTable.getList();
          this.showRemarkDialog = false;
        }, 1200);
      });
    },
    showChangeHandlekDialog(row) {
      this.showHandleDialog = true;
      this.currentId = row.id;
    },
    approved() {
      intendCustomer_deal({
        id: this.currentId,
        status: this.handleStatus,
      }).then((res) => {
        this.$message.success("操作成功");
        setTimeout(() => {
          this.showHandleDialog = false;
          this.$refs.myTable.getList();
        }, 1500);
      });
    },

    province_code(codes) {
      codes = codes.split(",");
      let _this = this;
      let names = [];
      for (let i = 0; i < codes.length; i++) {
        let result = _this.regionTree.find((item) => {
          return codes[i] == item.code;
        });
        if (result) {
          names.push(result.name);
        }
      }
      return names;
    },
  },
};
</script>
	